const { createFFmpeg, fetchFile } = require("@ffmpeg/ffmpeg");
const ffmpeg = createFFmpeg({ log: true });

// currently only uses mp4, but could be configured so that the users selects the file type
export const fileTypes = {
  webm: {
    mimeType: "video/webm",
    download: download,
    ext: "webm",
  },
  mov: {
    mimeType: "video/quicktime",
    download: downloadMov,
    ext: "mov",
  },
  mp4: {
    mimeType: "video/mp4",
    download: downloadMp4,
    ext: "mp4",
  },
};

export async function loadFfmpeg(btn, input) {
  await ffmpeg.load();
  btn.disabled = false;
  input.disabled = false;
}

function downloadMov(blob, fileName) {
  webmToMov(blob).then((res) =>
    download(
      new Blob([res.buffer], { type: fileTypes.mov.fileTypes }),
      fileName
    )
  );
}

function downloadMp4(blob, fileName) {
  webmToMp4(blob).then((res) =>
    download(new Blob([res.buffer], { type: fileTypes.mp4.mimeType }), fileName)
  );
}

export const download = (blob, fileName) => {
  genBtn.value = "done";
  genBtn.disabled = true;
  const a = document.createElement("a");
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
  }, 100);
};

async function webmToMp4(blob) {
  ffmpeg.FS("writeFile", "test.mp4", await fetchFile(blob));
  await ffmpeg.run("-i", "test.mp4", "-crf", "25", "-vsync", "0", "out.mp4");
  return ffmpeg.FS("readFile", "out.mp4");
}

// TODO: no video
async function webmToMov(blob) {
  ffmpeg.FS("writeFile", "test.mov", await fetchFile(blob));
  await ffmpeg.run("-i", "test.mov", "-c:v", "qtrle", "out.mov");
  return ffmpeg.FS("readFile", "out.mov");
}
