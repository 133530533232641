// highlight data
// dev URL
// const baseURL = "https://origin-embed.dev.lvn.org/highlight";
const baseURL = "https://embed.lvn.org/highlight";
let highlightId;
let highlight;
let snippets;
let words;

// audio/video
let audio;
let fileName;
import { fileTypes, loadFfmpeg } from "./download";

// app
let input;
let genBtn;
let form;
let requestAnimationFrameId;
let scrollAmt;
let error;
let scroll = false;
let logoImage;
const initialTextX = 50;
const initialTextY = 375;
// TODO: animate this value to scroll transcript
const state = {
  currentTextY: initialTextY,
};
let canvas;
let ctx;

// style
const lvnPurple = "#6352d9";
const activeFillStyle = lvnPurple;
const defaultFillStyle = "white";
const activeYPadding = 10;
const activeXPadding = 10;

window.onload = setup;
function setup() {
  error = document.getElementById("error");
  audio = document.querySelector("audio");
  audio.crossOrigin = "anonymous";
  audio.addEventListener(
    "error",
    () => {
      genBtn.disabled = true;
      error.textContent = "There was an error loading the highlight audio.";
    },
    true
  );

  genBtn = document.getElementById("genBtn");
  form = document.querySelector("form");
  form.onsubmit = handleSubmit;
  input = document.getElementById("hid");
  input.addEventListener("focusin", handleFocus);
  logoImage = new Image();
  logoImage.src = require("./logo.svg");
  updateHighlightIdFromURL();
  loadFfmpeg(genBtn, input);

  canvas = document.querySelector("canvas");
  canvas.width = 1280;
  canvas.height = 720;
  ctx = canvas.getContext("2d");
  ctx.textAlign = "left";
  ctx.textBaseline = "top";
  ctx.font = "4em Open Sans";
  // load font
  ctx.fillText("", 0, 0);
}

function fetchHighlight(highlightId) {
  const url = `${baseURL}/${highlightId}`;
  return fetch(url)
    .then((response) => response.json())
    .then((response) => {
      if (response.status !== "success") {
        console.error(response);
        throw new Error(response.data);
      }
      return response;
    })
    .then((response) => response.data)
    .catch((err) => {
      error.textContent = "There was a problem accessing this highlight.";
      genBtn.disabled = true;
      console.error(err);
    });
}

async function updateHighlightData(highlightId) {
  genBtn.value = "getting highlight...";
  const response = await fetchHighlight(highlightId);
  highlight = response.annotation;
  snippets = response.snippets;
  words = getHighlightWords(highlight);
}

function getHighlightWords(highlight) {
  const { audio_start_offset, audio_end_offset } = highlight;
  const words = snippets.map((s) => s.words).flat();
  return words.filter((w) => w[1] >= audio_start_offset);
}

function updateHighlightIdFromInput() {
  highlightId = input.value;
  fileName = highlightId;
  const params = new URLSearchParams(window.location.search);
  params.set("hid", highlightId);
  history.replaceState(null, null, `?${params.toString()}`);
}

function updateHighlightIdFromURL() {
  const params = new URLSearchParams(window.location.search);
  input.value = params.get("hid");
}

function handleSubmit(e) {
  e.preventDefault();
  generate();
}

function handleFocus(e) {
  if (genBtn.value !== "done") {
    clearError();
  }
}

function clearError(e) {
  genBtn.disabled = false;
  error.textContent = "";
}

async function generate() {
  updateHighlightIdFromInput();
  await updateHighlightData(highlightId);
  genBtn.value = "recording...";
  const audioUrl = `${baseURL}/${highlightId}/play`;
  audio.src = audioUrl;
  audio.load();

  const context = new AudioContext();
  const src = context.createMediaElementSource(audio);
  const dest = context.createMediaStreamDestination();
  src.connect(dest);

  const wordMetrics = ctx.measureText("Moo");
  const linePadding = 20;
  const lineHeight =
    wordMetrics.fontBoundingBoxAscent +
    wordMetrics.fontBoundingBoxDescent +
    linePadding;

  scrollAmt = lineHeight * 3;

  function renderFrame() {
    requestAnimationFrameId = requestAnimationFrame(renderFrame);

    drawBackground(ctx);
    if (scroll) {
      // TODO: animations
      // anime({
      //   targets: state,
      //   currentTextY: state.currentTextY - scrollAmt,
      //   easing: "easeOutQuad",
      // });
      state.currentTextY -= scrollAmt;
      scroll = false;
    }
    drawHighlight(ctx, words);
    drawOverlay();
    drawLogo();
  }

  function drawLogo() {
    ctx.drawImage(logoImage, 0, 0, 432, 283);
  }

  // keeps text from looking too funky
  function drawBackground(ctx) {
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }

  function drawHighlight(ctx, highlightWords) {
    let y = state.currentTextY;
    let x = initialTextX;
    const { audio_start_offset, audio_fade_in_out = 1 } = highlight;
    const spaceWordMetrics = ctx.measureText(" ");
    const currentTime =
      audio_start_offset - audio_fade_in_out + audio.currentTime;
    highlightWords.forEach((w, i) => {
      const word = w[0] + " ";
      const wordMetrics = ctx.measureText(w[0]);
      const lineWidth = wordMetrics.width + spaceWordMetrics.width + x;
      if (lineWidth > canvas.width - initialTextX) {
        y += lineHeight;
        x = initialTextX;
      }
      // use placeholder values for the first and last word
      const prevWord = i === 0 ? ["", 0, 0] : highlightWords[i - 1];
      const nextWord =
        i === highlightWords.length - 1
          ? ["", w[2], w[2]]
          : highlightWords[i + 1];
      const active = currentTime >= prevWord[2] && currentTime <= nextWord[1];
      ctx.fillStyle = defaultFillStyle;
      if (active) {
        ctx.fillStyle = activeFillStyle;
        ctx.fillRect(
          x - activeXPadding,
          y - activeYPadding,
          wordMetrics.width + 2 * activeXPadding,
          lineHeight - (linePadding + 10)
        );
        ctx.fillStyle = defaultFillStyle;
        ctx.fillText(word, Math.floor(x), Math.floor(y));
        if (y === scrollAmt + initialTextY) {
          scroll = true;
        }
      } else {
        ctx.fillText(word, Math.floor(x), Math.floor(y));
      }
      x += wordMetrics.width + spaceWordMetrics.width;
    });
  }

  // hide parts transcript except for up to three relevant lines
  function drawOverlay() {
    ctx.fillStyle = "#000000";
    ctx.fillRect(0, 0, canvas.width, Math.floor(initialTextY - lineHeight / 4));
    ctx.fillRect(0, initialTextY + scrollAmt - 10, canvas.width, canvas.height);
  }

  function startRecording() {
    const { download, mimeType, ext } = fileTypes.mp4;
    const chunks = [];
    const stream = canvas.captureStream();

    let combined = new MediaStream([
      ...stream.getTracks(),
      ...dest.stream.getTracks(),
    ]);

    const rec = new MediaRecorder(combined, {
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 2500000,
      mimeType: "video/webm", // mimeType after stream, not final mimeType
    });

    rec.ondataavailable = (e) => chunks.push(e.data);
    rec.onstop = () => {
      genBtn.value = "converting file (this will take a while)...";
      download(new Blob(chunks, { type: mimeType }), `${fileName}.${ext}`);
      // stop redrawing canvas
      window.cancelAnimationFrame(requestAnimationFrameId);
    };
    rec.start();

    // stop recording when audio is over
    audio.addEventListener("durationchange", () => {
      setTimeout(() => rec.stop(), Math.floor(audio.duration * 1000));
    });
  }

  audio.play();
  renderFrame();
  startRecording();
}
